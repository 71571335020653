.floatlabel-demo {
  .p-field {
    margin-top: 2rem;
    margin-bottom: 0;
  }
}

.input-demo {
  .p-multiselect {
    min-width: 15rem;
  }

  .multiselect-custom {
    .country-item {
      display: flex;
      align-items: center;

      span.flag {
        width: 18px;
        height: 12px;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }

    .country-item-value {
      border-radius: 3px;
      display: inline-flex;
      vertical-align: top;
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      background-color: #2196f3;
      color: #ffffff;
    }
  }
}

.list-demo {
  .product-name {
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    img {
      width: 150px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      margin-right: 2rem;
    }

    .product-list-detail {
      flex: 1 1 0;
      -ms-flex: 1 1 0px;
    }

    .p-rating {
      margin: 0 0 0.5rem 0;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      align-self: flex-end;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .product-grid-item {
    margin: 0.5em;
    border: 1px solid var(--surface-d);

    .product-grid-item-top,
    .product-grid-item-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    img {
      width: 75%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      margin: 2rem 0;
    }

    .product-grid-item-content {
      text-align: center;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 576px) {
    .product-list-item {
      flex-direction: column;

      img {
        width: 75%;
        margin: 2rem 0;
      }

      .product-list-detail {
        text-align: center;
      }

      .product-price {
        align-self: center;
      }

      .product-list-action {
        display: flex;
        flex-direction: column;
      }

      .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.media-demo {
  .product-item {
    .product-item-content {
      border: 1px solid var(--surface-d);
      border-radius: 3px;
      margin: 0.3rem;
      text-align: center;
      padding: 2rem 0;
    }

    .product-image {
      width: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }
}

.menu-demo {
  .stepsdemo-content {
    padding: 1em 0;

    p {
      font-weight: 400;
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      margin: 0;
    }

    i {
      vertical-align: middle;
      font-size: 1.5em;
      margin: 0;
    }
  }

  .contextmenu-image {
    width: 100%;
  }
}

.messages-demo {
  .p-field > label {
    width: 125px;
  }

  .p-inputtext {
    margin-right: 0.5rem;
  }
}

.misc-demo {
  .badges {
    .p-badge,
    .p-tag {
      margin-right: 0.5rem;
    }
  }

  .p-button {
    margin-right: 0.5rem;
  }

  .p-overlay-badge {
    margin-right: 2rem;
  }

  @keyframes p-progress-spinner-color {
    100%,
    0% {
      stroke: #2196f3;
    }
    40% {
      stroke: #ec407a;
    }
    66% {
      stroke: #66bb6a;
    }
    80%,
    90% {
      stroke: #ffa726;
    }
  }
}

.overlay-demo {
  p {
    line-height: 1.5;
    margin: 0;
  }

  .product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.panel-demo {
  p {
    line-height: 1.5;
    margin: 0;
  }

  .p-card .p-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.table-demo {
  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
      background-color: #607d8b;
    }
  }

  .p-datatable .p-column-filter {
    display: none;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }

    .p-paginator {
      padding: 1rem;
    }

    .p-datatable-thead > tr > th {
      text-align: left;
    }

    .p-datatable-tbody > tr > td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-unqualified {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-negotiation {
      background: #feedaf;
      color: #8a5340;
    }

    &.status-new {
      background: #b3e5fc;
      color: #23547b;
    }

    &.status-renewal {
      background: #eccfff;
      color: #694382;
    }

    &.status-proposal {
      background: #ffd8b2;
      color: #805b36;
    }
  }

  .p-progressbar-value.ui-widget-header {
    background: #607d8b;
  }

  @media (max-width: 640px) {
    .p-progressbar {
      margin-top: 0.5rem;
    }
  }

  .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .orders-subtable {
    padding: 1rem;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
      background: #c8e6c9;
      color: #256029;
    }

    &.order-cancelled {
      background: #ffcdd2;
      color: #c63737;
    }

    &.order-pending {
      background: #feedaf;
      color: #8a5340;
    }

    &.order-returned {
      background: #eccfff;
      color: #694382;
    }
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.p-datatable-customers {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          border-bottom: 1px solid var(--surface-d);

          > td {
            text-align: left;
            display: flex;
            align-items: center;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: 0.5rem;
              width: 100%;
            }

            .p-rating {
              display: inline-block;
            }
          }

          &.p-rowgroup-footer {
            > td {
              display: inline-flex;
              width: auto !important;
              float: none;
            }
          }
        }
      }
    }
  }
}

.crud-demo {
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  /* Responsive */
  .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.datatable-responsive {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          border-bottom: 1px solid var(--surface-d);

          > td {
            text-align: left;
            display: flex;
            align-items: center;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }

            .actions {
              display: flex;
              flex-grow: 1;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.elevation-demo {
  .box {
    min-height: 100px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    margin: 2rem;
    border-radius: 4px;
  }

  .shadow-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.flexbox-demo {
  .p-d-flex > div,
  .box {
    background-color: var(--surface-e);
    text-align: center;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  .p-d-flex > div {
    width: 8rem;
  }

  i:not([class~="pi"]) {
    background-color: #ffffff;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    padding: 2px 4px;
    letter-spacing: 0.5px;
    border-radius: 3px;
    font-weight: 600;
    margin: 0 2px;
  }
}

.flexgrid-demo {
  .box {
    background-color: var(--surface-e);
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  .box-stretched {
    height: 100%;
  }

  .vertical-container {
    margin: 0;
    height: 200px;
    background: var(--surface-d);
    border-radius: 4px;
  }

  .nested-grid .p-col-4 {
    padding-bottom: 1rem;
  }

  .dynamic-box-enter-active,
  .dynamic-box-leave-active {
    transition: all 0.5s;
  }

  .dynamic-box-enter,
  .dynamic-box-leave-to {
    opacity: 0;
  }

  .dynamic-box-enter,
  .dynamic-box-leave-to {
    transform: translateX(30px);
  }

  p {
    margin: 0;
  }
}

.icons-demo {
  .icon-filter {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0 1.5rem 0;
  }

  .icons-list {
    text-align: center;
    color: var(--text-color);

    .p-md-2 {
      padding: 1em;
    }
  }

  .icons-list i {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--text-color-secondary);
  }
}

.spacing-demo {
  .demo-container {
    border: 1px solid var(--surface-d);
  }
}

.text-demo {
  .demo-container {
    border: 2px solid #dee2e6;
  }
}

pre[class*="language-"] {
  &:before,
  &:after {
    display: none !important;
  }

  code {
    border-left: 10px solid var(--surface-d) !important;
    box-shadow: none !important;
    background: var(--surface-b) !important;
    margin: 1em 0;
    color: var(--text-color);
    font-size: 14px;

    .token {
      &.tag,
      &.keyword {
        color: #2196f3 !important;
      }

      &.attr-name,
      &.attr-string {
        color: #2196f3 !important;
      }

      &.attr-value {
        color: #4caf50 !important;
      }

      &.punctuation {
        color: var(--text-color);
      }

      &.operator,
      &.string,
      &.entity,
      &.url,
      &.variable {
        background: transparent;
      }
    }
  }
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  flex-direction: column;
}

.loading {
  width: 370px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.loading span {
  width: 40px;
  height: 40px;
  background-color: #2196f3;
  border-radius: 50%;
  display: block;
  transform: scale(1);
  transform-origin: center center;
  animation: circle 0.2s infinite ease-in-out 0s;
  visibility: hidden;
}

.loading h6 {
  width: 100%;
  margin: 0px;
  font-size: 1.5rem;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 15px;
  color: black;
  text-align: center;
  font-weight: 700;
}

.loading-box h6 {
  width: 100%;
  margin: 0px;
  font-size: 1.5rem;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 15px;
  color: black;
  text-align: center;
  font-weight: 700;
}

@keyframes circle {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 5px 5px #fff;
    visibility: visible;
  }
  50% {
    transform: scale(0.25);
    box-shadow: none;
  }
}

.loading span:nth-child(1) {
  animation: circle 1s infinite ease-in-out 0.2s;
}

.loading span:nth-child(2) {
  animation: circle 1s infinite ease-in-out 0.4s;
}

.loading span:nth-child(3) {
  animation: circle 1s infinite ease-in-out 0.6s;
}

.loading span:nth-child(4) {
  animation: circle 1s infinite ease-in-out 0.8s;
}

.loading span:nth-child(5) {
  animation: circle 1s infinite ease-in-out 1s;
}

.date-input {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #495057;
  background: #fff;
  border: 0;
  appearance: none;
  border-radius: 4px;
}

.date-input:enabled:hover {
  border-color: #90caf9;
}

.date-input:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #b1dafb;
  border-color: #90caf9;
}

.date-input-disable {
  color: #495057;
  opacity: 0.6;
}

.notification {
  width: 2.7rem;
  height: 2.7rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: 0 none;
}

.notification:hover {
  background: #f2f2f2;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 20%;
}

.notification .badge {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
  border-radius: 50%;
  font-weight: 800;
  background: #ff4141;
  color: #ffffff;
  border: 1px solid white;
}
.pi-bell {
  font-size: 1.3rem;
}

.p-column-filter {
  width: 100%;
}

//
.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #007ad9;
}
.p-timeline .p-timeline-event-connector {
  background-color: #c8c8c8;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-fieldset {
  background-color: #f8f9fa;
}

.p-fieldset .p-fieldset-legend {
  font-weight: bold;
}

.p-inputtext {
  color: #000;
}
.p-disabled,
.p-component:disabled {
  color: #495057;
  opacity: 0.6;
}

.reseller {
  min-width: 450px;
}

@media screen and (max-width: 960px) {
  .reseller {
    width: 90%;
  }
}
@media screen and (max-width: 640px) {
  .reseller {
    width: 100%;
    min-width: 0;
  }
}
.reseller-bg {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.price {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.price-text-green {
  background-color: #c8e6c9;
  color: #256029;
}
.price-text-red {
  background-color: #ffcdd2;
  color: #c63737;
}
.price-text-yellow {
  background-color: #f8dabc;
  color: #805b36;
}
.price-text-gray {
  background-color: #d2d2d2;
  color: #545353;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem;
}
.reseller-bg {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.reseller-bg-warning {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding-top: 40px;
  align-items: center;
  min-height: 100vh;
}

.reseller-bg.notfound {
  background-image: url(../../assets/images/bg-404.jpg);
}
.reseller-bg-warning.notfound {
  background-image: url(../../assets/images/bg-404.jpg);
}
.kvkk-sendsms:hover {
  text-decoration: underline;
  cursor: pointer;
}

#login-tabview > div {
  background-color: transparent !important;
}
#login-tabview > ul > li > a {
  background-color: #f8f9fa;
}
#login-tabview > ul > li > a > i {
  margin-right: 9px;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.instock {
  background: #c8e6c9;
  color: #256029;
}
.outofstock {
  background: #ffcdd2;
  color: #c63737;
}

.hover-item {
  transition: transform 0.3s;
}
.hover-item:hover {
  transform: scale(1.04);
}
.new {
  position: absolute !important;
  left: -70px !important;
  top: -70px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}

.best-sellers {
  position: absolute !important;
  left: -70px !important;
  top: -70px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}
.new-best-sellers {
  position: absolute !important;
  left: -38px !important;
  top: -70px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}
.new-new-best-sellers {
  position: absolute !important;
  left: -20px !important;
  top: -60px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}
.new-similar {
  position: absolute !important;
  left: -45px !important;
  top: -30px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}
.new-best-sellers-similar {
  position: absolute !important;
  left: -25px !important;
  top: -20px;
  width: 130px !important;
  z-index: 222;
  justify-content: center;
}

.basketSideBar {
  padding: 0px;
}

.basket-delete-icon:hover {
  background-color: white;
  transition: 0.5s;
  transform: scale(1.24);
  border-radius: 99px;
}
#basket-delete-icon:hover {
  color: #fff;
}
.basket-header {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.template .p-button i {
  line-height: 2.25rem;
}
.template .p-button.detail {
  background: linear-gradient(to left, #852196 50%, #6d1b7b 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: #6d1b7b;
}
.template .p-button.detail:hover {
  background-position: left bottom;
  background-color: #6d1b7b;
}
.template .p-button.detail i {
  background-color: #6d1b7b;
}
.template .p-button.detail:focus {
  box-shadow: 0 0 0 1px #af50bf;
}

.p-chip.label-chip {
  background-color: #2196f3 !important;
  color: #fff !important;
  // padding-inline-end: 6px;
}

.filter-item {
  background: #feedaf;
  color: #8a5340;
}
.remove-filter:hover {
  cursor: pointer;
}
