.tabview-demo .tabview-custom i,
.tabview-demo .tabview-custom span {
  vertical-align: middle;
}

.tabview-demo .tabview-custom span {
  margin: 0 0.5rem;
}

.tabview-demo .p-button {
  margin-right: 0.25rem;
}

.tabview-demo .p-tabview p {
  line-height: 1.5;
  margin: 0;
}
.layout-content {
  padding: 0;
}

.p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-chip {
  background-color: #dee2e6;
  color: #495057 !important;
  border-radius: 16px;
  padding: 0 0.5rem;
}


.virtualscroller-demo .scroll-item {
  display: flex;
  align-items: center;
}

.virtualscroller-demo .custom-scroll-item {
  flex-direction: column;
  align-items: stretch;
}

.virtualscroller-demo .odd {
  background-color: var(--surface-b);
}

.virtualscroller-demo .p-virtualscroller {
  height: 50px;
  /* width: 100%; */
  border: 1px solid var(--surface-d);
}

.virtualscroller-demo .p-horizontal-scroll .p-virtualscroller-content {
  display: flex;
  flex-direction: row;
}

.virtualscroller-demo .p-horizontal-scroll .scroll-item {
  writing-mode: vertical-lr;
}
.p-virtualscroller-content{
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
}
