.dataview-demo .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.dataview-demo .product-name {
  height: 70px;
  font-weight: 700;
}

.dataview-demo .product-description {
  margin: 0 0 1rem 0;
  height: 30px;
}

.dataview-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.dataview-demo .product-category {
  font-weight: 600;
  font-size: 12px;

  vertical-align: middle;
}

.dataview-demo .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.dataview-demo .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.dataview-demo .product-list-item .product-list-detail {
  flex: 1 1 0;
}

.dataview-demo .product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.dataview-demo .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

.dataview-demo .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.dataview-demo .product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.dataview-demo .product-grid-item {
  margin: 0.5em;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
  padding: 2rem;
}

.dataview-demo .product-grid-item .product-grid-item-top {
  align-items: center;
  justify-content: flex-end;
}
.dataview-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .dataview-demo .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 1rem 0;
} */

/* .dataview-demo .product-grid-item .product-grid-item-content {
  text-align: center;

} */

.dataview-demo .product-grid-item .product-price {
  font-size: 1.2rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .dataview-demo .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .dataview-demo .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .dataview-demo .product-list-item .product-list-detail {
    text-align: center;
  }

  .dataview-demo .product-list-item .product-price {
    align-self: center;
  }

  .dataview-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .dataview-demo .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
