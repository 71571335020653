@import './AppDemo.scss';

.marginInput {
  margin-top: 30px;
  margin-bottom: 30px;
}

.whiteSmokeRow{
  background: #F5F5F5	 !important;
}

@media screen and (max-width: 900px) {
  .small_hidden {
    display: none !important;
  }
}

@media screen and (min-width: 900px) {
  .small_show {
    display: none !important;
  }
}

html, body {
  overflow-x: hidden;
}
